import { useStaticQuery, graphql } from 'gatsby'

export const QueryGraphql = () => {
  let data = useStaticQuery(graphql`
    query HomeData {
      allContentfulTestimonial {
        nodes {
          title
          name
          message {
            message
          }
        }
      }
      allContentfulHero {
        nodes {
          header
          mainDescription
          backgroundColor
          title
          desc {
            title
          }
        }
      }
      allContentfulHomepage {
        nodes {
          content {
            raw
          }
          contentfulid
        }
      }
      allContentfulWorks {
        nodes {
          id
          title
          slug
        }
      }
      allContentfulSolution {
        nodes {
          solutionsType: contentfulid
          titleAndContent {
            title
            content
          }
        }
      }
    }
  `)

  return data
}
