import React from 'react'
import './reportcards.scss'

export const ReportCards = ({
  backgroundType,
  date,
  shortDescription,
  title,
}) => {
  return (
    <div
      className={`rep-card-container ${
        backgroundType ?? `bg-report-2`
      } font-white pd-20 br-20`}
    >
      <div className="col">
        <h5>{date ?? `10/09/2022`}</h5>
        <span className="ft-bold">
          {title ?? `How to create surveys which will produce maximum results.`}
        </span>
        <p className="ft-light ">
          {shortDescription ??
            `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Volutpat id
          enim ac egestas nibh ac tellus. Vestibulum tempor non arcu, donec
          scelerisque elementum molestie volutpat elit.`}
        </p>
      </div>
    </div>
  )
}
