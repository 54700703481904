import { Link } from 'gatsby'
import React from 'react'
import CTA from '../CTA'
import Puzzle from '../../images/puzzle.png'

import './hero.scss'

export const HeroData = {
  title: 'AKTA SOLUTIONS',
  description: [
    {
      info: `AKTA Analytics combines our 3 pillars of excellence; data, tech
    and marketing to guide clients through the business process.`,
    },
    {
      info: `We provide consultation from the theoretical and strategic
    documentation stage through to its execution and optimization.`,
    },
  ],
  backgroundColor: {
    primary: 'bg-primary-main',
  },
}

const Hero = ({
  title,
  description,
  backgroundColor,
  image,
  isButton,
  buttonTitle,
  titleColor,
}) => {
  // console.log({back})
  return (
    <div
      className={`hero-container ${
        backgroundColor ?? HeroData.backgroundColor.primary
      } `}
    >
      <div className="col row-mobile">
        <div className="row mt-5 mobile-center">
          <h1 className={`lh-10  ${titleColor ?? ''}`}>
            {title ?? HeroData.title}
          </h1>
        </div>
        <div className="row mt-5 sm-text-center">
          <div>
            {description?.map((val, ind) => (
              <p key={ind}>{val?.info ?? 'description1 '}</p>
            )) ??
              HeroData.description.map(({ info }, ind) => (
                <p key={ind}>{info}</p>
              ))}
          </div>
        </div>
        <div className="row">
          {isButton ? (
            <Link to="/take-a-survey">
              <CTA
                type={`matte-black br-10`}
                height="60px"
                width={`400px`}
                size="0.8em"
              >
                {buttonTitle ?? `Contact Us`}
              </CTA>
            </Link>
          ) : (
            ''
          )}
        </div>
      </div>
      <div className="col">
        <div className="hero-svg">
          <img
            src={image ?? Puzzle}
            alt="puzzle"
            width="450"
            className="ml-10 hero-image"
          />
        </div>
      </div>
    </div>
  )
}

export default Hero
