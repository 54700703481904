import { Link } from 'gatsby'
import React, { useEffect, useState } from 'react'
import Footer from '../../components/Footer'
import Hero from '../../components/Hero/Hero'
import { ReportCards } from '../../components/ReportCards/ReportCards'
import LadyOnPencilImage from '../../images/hero/reports/lady.png'

import Page1 from '../../images/Report/1'
import Page10 from '../../images/Report/10'
import Page2 from '../../images/Report/2'
import Page3 from '../../images/Report/3'
import Page4 from '../../images/Report/4'
import Page5 from '../../images/Report/5'
import Page6 from '../../images/Report/6'
import Page7 from '../../images/Report/7'
import Page8 from '../../images/Report/8'
import Page9 from '../../images/Report/9'
import { ReportCard1Image } from '../../images/report_cards/report_cards_1.png'
import { QueryGraphql } from '../../utils/graphQlQuery'
import { ReportsData } from '../../utils/GraphQL/reportsQueryData'
import './report.scss'

const HeroData = {
  title: 'Views from AKTA',
  description: [
    {
      info: `Check out our collection of work and case studies.`,
    },
    {
      info: `Sign Up to our newsletter to be alerted of new project releases and insights.`,
    },
  ],
  backgroundColor: {
    orange: 'bg-light-orange',
  },
}

const Reports = () => {
  let data = QueryGraphql()
  const {
    allContentfulReports: { nodes: reportData },
  } = ReportsData()


  const {
    allContentfulHero: { nodes },
  } = data

  // const mainData = desc.find((subject) => subject.title === 'Akta Pricing')
  const mainData = nodes
    .filter((val) => val.header === 'Akta Reports')
    .filter((val) => {
      const { desc: mainDesc } = val
      return mainDesc ?? val
    })

  const { header, mainDescription, title: info, backgroundColor, slug } = mainData[0]

  const fullDescription = mainDescription.map((val, ind) => {
    let description = {}
    description.info = val
    return description
  })


  const pageDescription = mainDescription
  const [pages, setPages] = useState(undefined)
  const [numPages, setNumPages] = useState(10)
  const [pageNumber, setPageNumber] = useState(0)

  useEffect(() => {
    const p = [
      <Page1 height="100vh" width="75vw" />,
      <Page2 height="100vh" width="75vw" />,
      <Page3 height="100vh" width="75vw" />,
      <Page4 height="100vh" width="75vw" />,
      <Page5 height="100vh" width="75vw" />,
      <Page6 height="100vh" width="75vw" />,
      <Page7 height="100vh" width="75vw" />,
      <Page8 height="100vh" width="75vw" />,
      <Page9 height="100vh" width="75vw" />,
      <Page10 height="100vh" width="75vw" />,
    ]

    setPages(p)
    if (pages !== undefined) {
      setNumPages(pages.length)
    }
  }, [])

  return (
    <div className="reportContainer">
      <Hero
        backgroundColor={backgroundColor ?? HeroData.backgroundColor.orange}
        title={info}
        description={fullDescription}
        image={LadyOnPencilImage}
        isButton={false}
      />


      {/* This prints the list of reports from Contentful
          The goal is to redirect the user to the selected report using 
          Gatbsy's dynamic routes
      */}
      <div className="reports-fluid-container">
        <div className="col">
          <div className="grid-col-span-2 mx-auto">
            {reportData &&
              reportData.map((val, ind) => (
                <Link key={ind} to={`/reports/reportdetails/${val?.slug}`} state={val}>
                  <ReportCards
                    key={ind}
                    backgroundType={`bg-report-1`}
                    date={val?.createdAt}
                    shortDescription={val?.shortDescription}
                    title={val?.title}
                  />
                </Link>
              ))}
          </div>


          {/*  <div className="row sp-ev">
            <Link to="/reports/reportdetails">
              <ReportCards backgroundType={`bg-report-1`} />
            </Link>
            <Link to="/reports/reportdetails">
              <ReportCards backgroundType={`bg-report-1`} />
            </Link>
          </div>
          <div className="row sp-ev">
            <Link to="/reports/reportdetails">
              <ReportCards />
            </Link>
            <Link to="/reports/reportdetails">
              <ReportCards />
            </Link>
          </div>
          <div className="row sp-ev">
            <Link to="/reports/reportdetails">
              <ReportCards backgroundType={`bg-report-1`} />
            </Link>
            <Link to="/reports/reportdetails">
              <ReportCards backgroundType={`bg-report-1`} />
            </Link>
          </div>
          <div className="row sp-ev">
            <Link to="/reports/reportdetails">
              <ReportCards />
            </Link>
            <Link to="/reports/reportdetails">
              <ReportCards />
            </Link>
          </div> */}
        </div>
      </div>

      <Footer />
    </div>
  )
}

export default Reports
